import UserListSortables from "@/components/templateux/sortable-form/users/user-list-sortables";
import { config, user_types } from "@/lib/config";
import { hasData } from "@/lib/utils/hasData";
import { useSWRWrapper } from "@/lib/utils/hooks/swr-hooks";
import { useEffect, useState } from "react";

export default function SortableFormUsers({
     type='guest'
    ,addbutton='Add more folks'
    ,inputJSON
    ,set_inputJSON
    ,unique_id
    ,owner_name
    ,user_roles=user_types
    ,set_changes
    ,timestamp
    ,max_users=0
    ,form_type
}) {

    const [activeField,set_activeField] = useState(0)
    const [single_name,set_single_name] = useState(null)
    const [initialLoad,set_initialLoad] = useState(true)


    //LOOKUP DATA
    const { 
      data: single_id
    , loading: iddataisLoading
    , error: iddataisError
                    } = useSWRWrapper(
                        single_name //id
                        ,`/api/public/v0/user/select-name?name=${single_name}` //key
                        ,true //retry?
                        ,config.max_retries //max number of retries
                        ,config.retry_interval //retry interval
                        ,0 //refreshinterval (0=none)
                        )
          
    useEffect(() => {
       


        //get the id value and append it to the host json
        if (!iddataisLoading && !iddataisError) {

            let this_field = (activeField <= inputJSON?.length) 
                                ? activeField-1
                                : inputJSON.length-1

            if (inputJSON[this_field]?.id?.toString() == "0" 
                || (single_id?.user_id?.toString() !== inputJSON[this_field]?.id?.toString()
                    && hasData(inputJSON[this_field]?.id?.toString()))) {

                let inputJSON_temp = inputJSON;
                inputJSON_temp[this_field].id = single_id?.user_id;
                inputJSON_temp[this_field].date = single_id?.timestamp;
                set_inputJSON(inputJSON_temp);

            }

        }

        
    },[
         single_id
        ,iddataisLoading
        ,iddataisError
        ,inputJSON
        ,activeField
    ])    




    return (<>
    
         {/* user_links_json */}
        <div className="my-4">

           
          
            <UserListSortables 
               inputJSON={inputJSON}
               set_inputJSON={set_inputJSON}
               type={type}
               addbutton_msg={addbutton}
               unique_id={unique_id}
               set_changes={set_changes}
               set_activeField={set_activeField}
               set_single_name={set_single_name}
               user_roles={user_roles}
               timestamp={timestamp}
               max_users={max_users}
               form_type={form_type}
             />

       </div>

    </>)
}