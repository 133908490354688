import React, { Fragment, useEffect, useState } from "react";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import SvgJsx from "@/components/templateux/svg/svg-jsx";
import ReactTooltip from "react-tooltip";
import { hasData } from "@/lib/utils/hasData";
import { useTimer } from "use-timer";
import { countUpSecondsOnly } from "@/lib/utils/countUp";
import { block_array, block_array_seconds, STAFF_PERMS } from "@/lib/config";
import BitwisePermissions from "./bitwise-permissions";
import { getBitwise } from "@/lib/utils/bitoperations";

interface SortableListUserProps extends React.InputHTMLAttributes<HTMLInputElement> {
  items?: any;
  handleInputChange?: any;
  handleRemoveFields?: any;
  handlePermissionsChange?: any;
  sorting?: any;
  user_roles?: any;
  timestamp?: any;
  form_type?: any;
}

export const SortableListUser = SortableContainer<SortableListUserProps>(({
                                         items
                                        ,handleInputChange
                                        ,handleRemoveFields
                                        ,handlePermissionsChange
                                        ,sorting
                                        ,user_roles
                                        ,timestamp
                                        ,form_type
                                      }) => {



  return (
    <div className={`rounded-lg border-4 ${sorting ? "border-blue-400 animate-pulse border-dashed " : "border-transparent"}`}>
      {items?.map((e, index) => (
        <Fragment key={`item-${index}`} >
        
        <SortableItemUser 
            e={e}
            index={index}  
            itemIndex={index}
            handleInputChange={handleInputChange}
            handleRemoveFields={handleRemoveFields}
            user_roles={user_roles}
            timestamp={timestamp}
            handlePermissionsChange={handlePermissionsChange}
            form_type={form_type}
        />
        </Fragment>
      ))}
    </div>
  );
});

interface SortableItemUserProps extends React.InputHTMLAttributes<HTMLInputElement> {
  e?: any;
  index?: any;
  itemIndex?: any;
  handleInputChange?: any;
  handleRemoveFields?: any;
  user_roles?: any;
  timestamp?: any;
  handlePermissionsChange?: any;
  form_type?: any;
}

export const SortableItemUser = SortableElement<SortableItemUserProps>(({
  e
 ,index
 ,itemIndex
 ,handleInputChange
 ,handleRemoveFields
 ,user_roles
 ,timestamp
 ,handlePermissionsChange
 ,form_type
}) => (     
       <>
         <FormfieldsUser
           e={e}
           index={itemIndex}
           handleInputChange={handleInputChange}
           handleRemoveFields={handleRemoveFields}
           user_roles={user_roles}
           timestamp={timestamp}
           handlePermissionsChange={handlePermissionsChange}
           form_type={form_type}
         /></>
     ));






export function FormfieldsUser({
                           e
                          ,index
                          ,handleInputChange
                          ,handleRemoveFields
                          ,user_roles
                          ,timestamp
                          ,handlePermissionsChange
                          ,form_type
                        }) {

  const { time } = useTimer({
    initialTime: 0, //Math.round(+new Date(timestamp) - +new Date(start_date)) / 1000,
    autostart: true,
    interval: 1000,
    timerType: "INCREMENTAL"
  });

  const [isMounted,setIsMounted] = useState(false); // Need this for the react-tooltip

  useEffect(() => {
      setIsMounted(true);
  },[]);


  return (
  <>
  <div className='flex items-top content-start w-full group'>
    <div 
      key={`${e}~${index}`}
      className={`rounded-lg flex-1 w-full flex flex-item mb-2  overflow-hidden border  
      border-blue-700 
        ${e[`open`] == '1' 
        ? `bg-blue-700 ` 
        : `bg-blue-900 hover:bg-blue-600`}
      `}
    >
      {/* CONTAINER ROW SET */}
      <div className={`${e[`open`]=='0' ? " block" : "hidden"} 
      flex-1 w-full my-auto flex items-center content-center
      cursor-pointer p-2
      `}
      id={`initial`}
      onClick={event => handleInputChange(index, event)}
      >

            {/* NAMES */}
            <div 
              className={`flex-1 w-full  flex items-center content-center cursor-pointer
                            ${e[`id`]?.toString() !== '0' ? 'text-white' : 'text-red-500'}
                            `}
              onClick={event => handleInputChange(index, event)}
              id={`initial`}                 
            > 

           


              {/* BLOCKS / TIMEOUTS - ONLY */}
              {form_type=='block' &&
              <div
                className="flex-0 px-2 py-0.5 mr-2 text-3xs w-10 text-center rounded-md text-white bg-blue-700 cursor-pointer whitespace-nowrap"
                onClick={event => handleInputChange(index, event)}
                id={`initial`}      
              >
              {`${block_array[e[`type`]][2]} `}
              </div>
              }

              {/* SHOWS (SCHEDULES AND BROADCASTS) - ONLY*/}
              {form_type=="show" &&
              <div
                className="flex-0 px-2 py-0.5 mr-2 text-3xs w-10 text-center rounded-md text-white bg-blue-700 cursor-pointer whitespace-nowrap"
                onClick={event => handleInputChange(index, event)}
                id={`initial`}      
              >
              {e[`type`]}
              </div>
              }

                    {/* NAMES*/}
                    <div 
                      className="flex-1 text-xs sm:text-sm cursor-pointer flex items-center content-center"
                      onClick={event => handleInputChange(index, event)}
                      id={`initial`}    
                    >
                      <div className="">
                      {e[`name`]}
                      </div>

                      


                    </div>

              {/* STAFF PERMISSIONS - ONLY */}
              {form_type=="staff" &&
              <div className="flex-0 ml-2 text-right"
              id={`initial`}   
              >

                  <RoleTags
                    perms={Object.keys(STAFF_PERMS)}
                    value={e[`permissions`]}
                    handleInputChange={handleInputChange}
                    index={index}
                  />
              </div>
              }

              
           


              
            </div>
      </div>

      {/* CONTAINER EDIT */}
      <div className={`${e[`open`]=='1' ? " block" : "hidden"} 
      w-full p-2
      `}>

        {/* TOP ROW */}
        <div
          className="flex-1 w-full my-auto flex items-center content-center"
        >

            {/* NAMES INPUT */}
            <div 
              className={`flex-1 w-full cursor-pointer  flex items-center content-center relative
                            ${e[`id`]?.toString() !== '0' ? 'text-white' : 'text-red-500'}
                            `}
              onClick={event => handleInputChange(index, event)}
              id={`initial`}                 
            > 

              <div
                className={`font-normal text-sm absolute z-100 inset-y-0 right-0 pr-3 flex items-center leading-5
                ${e[`id`]?.toString() == '0'
                  ? 'text-red-500'
                  : 'text-green-500'}
                `}
                data-tip={e[`id`]?.toString() == '0'
                  ? 'Not found'
                  : 'Great success'}
                
                data-for={`inputform`}
              >
                <SvgJsx 
                  type={'fill'}
                  icon={e[`id`]?.toString() == '0'
                        ? 'exclamation-circle-sm' 
                        : 'check-circle-sm'}
                  className={` h-6 w-6 `}
                  title={e[`id`]?.toString() == '0'
                        ? 'Not found' 
                        : 'Looks good'}
                />
              </div>

              <div className="relative w-full">
                <input
                  type="text"
                  autoComplete="off" 
                  className={`w-full block py-2 px-2 rounded-md border 
                  text-xs sm:text-lg
                  ${e[`id`]?.toString() !== '0' 
                  ? `text-white border-transparent` 
                  : `text-red-500 border-red-500`
                  }
                  text-white placeholder-gray-500 bg-gray-800
                    `}
                  id={`name`}
                  name={`name`}
                  value={e[`name`]}
                  onChange={event => {
                    event.preventDefault();
                    handleInputChange(index, event);
                  }}
                  onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                  placeholder='Username'
                
                  onFocus={event => handleInputChange(index, event)}
                  // rows={2}
                  // maxRows={1}
                />
              </div>
            </div>

            {/* CLOSE */}
            <div className="flex-0">

              <div 
                  className={`rounded-md px-4 py-2 ml-1 cursor-pointer  text-center   
                          text-xs sm:text-lg 
                             bg-gray-800 dark hover:bg-gray-700
                              `}
                  id={`open`}
                  onClick={event => handleInputChange(index, event)}
                >
                    set
                </div>

            </div>


          

        </div>

        {/* ADVANCED BLOCK */}
        <div 
                className={` w-full  ${(e[`open`]=='1' && ["ignore"].indexOf(e[`type`]) == -1) ? " block" : "hidden"}`}
              >
              <div 
                className={`mt-2  ${(e[`open`]=='1' && ["mod"].indexOf(e[`type`]) == -1) ? " block" : "hidden"}`}
              >

                {/* TYPE */}
                <label 
                  htmlFor={`type`}
                  className="font-bold text-xs sm:text-lg text-gray-200"
                >
                  Type
                </label>
                <div>
                <select
                    className="form-control w-full
                    rounded-md
                   text-white placeholder-gray-500 bg-gray-800
                    text-xs sm:text-lg
          "
                    id={`type`}
                    name={`type`}
                    value={e[`type`]}
                    onChange={event => {
                      handleInputChange(index, event)
                      //e[`fieldOptionPlaceholder`]=sortableTypeArray[event.target.value][1];
                    }}
                  >
                   
                      {user_roles.map((e,i)=> 
                        <option 
                          value={user_roles[i][1]}
                          key={i}
                        >
                          {[user_roles[i][0]]}
                        </option> 
                        
                      )}
                    
                  </select>
                  </div>
                
                
              </div>

              {hasData(e[`timestamp`]) && ['1','2','3','4','5','6'].includes(e[`type`]) &&
              <>
                {/* <div className="mt-2">
                 
                  <ChatTimer
                      start_date={+new Date(e[`date`])}
                      timestamp={+new Date(timestamp)}
                      time={time}
                      chat_id={e[`id`]}
                      id={0}
                  /> 
                </div> */}
                <div className="mt-2">
                  {/* {timerFormat(countUpBySeconds(Math.round(block_array_seconds(e[`type`]) - countUpSecondsOnly(e[`date`],timestamp))))} */}
                  {countUpSecondsOnly(e[`timestamp`],timestamp) <= block_array_seconds(e[`type`]) ? `${Math.round((block_array_seconds(e[`type`]) - countUpSecondsOnly(e[`timestamp`],timestamp)) / 60)} minutes remaining` : "expired"}
                </div>
              </>
              }

              

              {/* PERMISSIONS */}
              <div 
                className={`${['mod'].includes(e[`type`]) ? "block" : "hidden"} mt-2`}
              >

                <BitwisePermissions
                  value={e[`permissions`]}
                  set_value={handlePermissionsChange}
                  index={index}
                  perms={STAFF_PERMS}
                  perms_title={`Management Access`}
                  type='buttons'
                />


                <input
                  className="w-full 
                  text-xs sm:text-lg
                  text-white placeholder-gray-500 bg-gray-800
                  "
                  type="hidden"
                  id={`permissions`}
                  name={`permissions`}
                  value={e[`permissions`]}
                  onChange={event => handleInputChange(index, event)}
                  placeholder='Additional Permissions'
                  autoComplete="off" 
                />
              </div>

              

              

              


             
              

              </div>

      </div>

    </div>

    {/* REMOVE */}
    <div className="flex-0 opacity-25 group-hover:opacity-100 h-10 flex items-center content-center">
      <div 
        className=" rounded-md  ml-1 cursor-pointer  text-center border
        hover:border-white border-transparent text-gray-200 hover:text-white
        "
        onClick={() => handleRemoveFields(index)}
      >
          <SvgJsx 
              type='fill' 
              icon={`x-circle-sm`} 
              className={` w-4 h-4 flex-0 my-auto
                         
              `}
              title='Checkbox'
            />
      </div>
    </div>
  </div>
  {isMounted && <ReactTooltip
    id={`inputform`}
    place={`bottom`} 
    effect='solid' 
    clickable={false}
    delayHide={200}
    delayUpdate={0}
    className="reacttooltip rounded-3xl shadow-lg"
  />}
  </>)



}

function RoleTags({
  perms
  ,value
  ,handleInputChange
  ,index
}) {
  return(<>
  {perms.map((p,i)=>  
      <Fragment
        key={i}
      >
              {getBitwise(value,perms)[p]
                ? <><div
                      className="inline-block px-2 py-0.5 mr-1 mt-1 text-sm text-center rounded-md text-white bg-blue-700 cursor-pointer"
                      id={`initial`}   
                      onClick={event => handleInputChange(index, event)}
                    >
                      {p}
                    </div>
                  </>
                : <></>}
      </Fragment>
    )}    
  </>)  
}        
