

export function getFieldsUser(
  index
 ,type='guest'
 ,unique_id
 ,timestamp
 ,permissions
 ) {

  return makeFieldUser(
     index
    ,0 //id
    ,1 //open
    ,''//name
    ,type //type
    ,permissions //0
    ,unique_id
    ,timestamp
  )

}

export function makeFieldUser(
                   index=0
                  ,id=0
                  ,open=1
                  ,name=''
                  ,type='cohost'
                  ,permissions=0
                  ,unique_id
                  ,timestamp

) {

const outstr =
 `{
       "open":"${open}"
      , "id":"${id}"
      , "name":"${name}"
      , "type":"${type}"
      , "timestamp":"${timestamp}"
      , "permissions":"${permissions}"
}`

//console.log("makefield",outstr)

return outstr
}

export function getStarterUser() {
  return (
  {
    "items": [
      {
        "open": "1",
        "name": "name1",
        "type": "contacts",
        "id": "1",
        "timestamp":"null",
        "permissions":"0"
      },
      {
        "open": "0",
        "name": "name2",
        "type": "contacts",
        "id": "2",
        "timestamp":"null",
        "permissions":"0"
      }
    ]
  }
  )
}