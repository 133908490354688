
import { PlusCircleIcon } from "@heroicons/react/solid";
import { useState } from "react";
import { arrayMove } from "react-sortable-hoc";
import { getFieldsUser } from "@/components/templateux/sortable-form/users/getFieldsUsers";
import { SortableListUser } from "@/components/templateux/sortable-form/users/sortables-users";
import { useConfirm } from "@/lib/utils/hooks/useConfirm";

export default function UserListSortables({
     inputJSON
    ,set_inputJSON
    ,type="guest"
    ,addbutton_msg="Add a guest"
    ,unique_id
    ,set_changes
    ,set_activeField
    ,set_single_name
    ,user_roles
    ,timestamp
    ,max_users=0
    ,users_title='staff'
    ,form_type
}) {

  const { isConfirmed } = useConfirm();

    const [indexCount, set_indexCount] = useState(0) //get the number of items in the array to use in future form population
    const [sorting,set_sorting] = useState(false)

    const handlePermissionsChange = async(index,value) => {
      
      set_activeField(index+1); 
      const values = [...inputJSON];
      values[index][`permissions`] = value;
      set_inputJSON(values);
      set_changes(true);
      
    }
  
    const handleInputChange = async (index, event) => {

      event.preventDefault();
     
      set_activeField(index+1); //we only use this to assign an id based on the name

      const values = [...inputJSON];
      if (event.target.id === `name`) {
        
        set_single_name(event.target.value);
        values[index][`name`] = event.target.value;

        //loop through others and set to 0
        values.map((e,i) => {
          if (i !== index) values[i][`open`] = 0;
        })
  
        values[index][`open`] = 1;
        
      } else if (event.target.id === `type`) {
        values[index][`type`] = event.target.value;
      } else if (event.target.id === `open`) {
        values[index][`open`] = 0;
        values.map((e,i) => {
          if (i !== index) values[i][`open`] = 0;
        })
      } else if (event.target.id === `initial`) {
        values[index][`open`] = 1;
        values.map((e,i) => {
          if (i !== index) values[i][`open`] = 0;
        })
      }
  
      set_inputJSON(values);
      set_changes(true);
    };
  
    const handleAddFields = (type='guest') => {
      const tempvalues = [...inputJSON];
      const newCount = indexCount+1;
      tempvalues.push(JSON.parse(getFieldsUser(newCount,type,unique_id,"","0")));
      set_inputJSON(tempvalues);
      set_indexCount(newCount);
      set_changes(true);
      const values = [...inputJSON];
      values.map((e,i) => {
        if (i !== newCount) values[i][`open`] = 0;
      })
    };
  
    const handleRemoveFields = async (index) => {
      //const confirmed = await isConfirmed("Are you sure you want to remove this user from this list?");
      const confirmed = await isConfirmed({title:`Are you sure you want to remove this user from this ${form_type} list?`
                                        ,body:<div className="text-2xl my-4 font-bold">{inputJSON[index]?.name}</div>});
      if (confirmed) {
        const tempvalues = [...inputJSON];
        tempvalues.splice(index, 1);
        set_inputJSON((tempvalues));
        set_indexCount(indexCount-1);
        set_changes(true);
      }
      
    };
    


    return (<>
    
    <div>
          <SortableListUser
             items={inputJSON}
             handleInputChange={handleInputChange}
             handleRemoveFields={handleRemoveFields}
             handlePermissionsChange={handlePermissionsChange}
             sorting={sorting}
             onSortStart={() => {
              set_sorting(true);
             }}
             onSortEnd={({oldIndex, newIndex}) => {
              set_inputJSON(arrayMove(inputJSON, oldIndex, newIndex));
              set_sorting(false);
             }} 
             axis='y' //x,y,xy possible
             lockAxis='y'
             useDragHandle={true}
             user_roles={user_roles}
             timestamp={timestamp}
             form_type={form_type}
          />

          {(max_users == 0 || inputJSON?.length < max_users) &&
            <div
              className='flex items-center content-center'>
              <div 
                onClick={() => handleAddFields(type)}  
                className="flex-0 ml-0 cursor-pointer relative inline-flex items-center  py-2 pl-3 pr-4 border  rounded-md shadow-sm 
                border-gray-700 hover:border-blue-400 "
              >
                <PlusCircleIcon className="h-5 w-5" aria-hidden="true" />
                <span className="ml-2.5 whitespace-nowrap">
                {addbutton_msg} 
                </span>
              </div>
            </div>
          }

            
            
          </div>      
    
    </>)
}